import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Label } from 'components/Forms/utilities/Label';
import { LazyIcon } from 'components/LazyIcon/LazyIcon';
import PasswordToggle from 'components/Forms/utilities/Textbox/PasswordToggle';
import SubText from 'components/Forms/utilities/SubText';
import EndAdornment from 'components/Forms/utilities/EndAdornment';

import { convertToDate } from 'utils/validation';

import styles from 'components/Forms/utilities/Textbox/Textbox.scss';

export default class Textbox extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      passwordIsVisible: false,
      passwordToggleClicked: false,
    };
  }

  handleFocus = event => {
    const { onFocus } = this.props;
    onFocus(event);
    this.setState({ hasFocus: true });
  };

  handleBlur = event => {
    const { onBlur, normalizeOnBlur } = this.props;

    if (normalizeOnBlur) {
      // eslint-disable-next-line
      event.target.value = normalizeOnBlur(event.target.value);
    }

    onBlur(event);

    this.setState({ hasFocus: false });
  };

  handleChange = event => {
    const { onChange, onChangeDate } = this.props;
    if (event.target) {
      onChange(event.target.value);
      onChangeDate(event.target.value);
    }
  };

  togglePasswordVisibility = e => {
    e.preventDefault();

    this.setState(prevState => ({
      passwordIsVisible: !prevState.passwordIsVisible,
      passwordToggleClicked: true,
    }));

    this.handleFocus();
  };

  render() {
    const {
      ariaDescribedBy,
      autoComplete,
      autofocus,
      dataWebviewid,
      disabled,
      endAdornment,
      helpText,
      id,
      label,
      maxlength,
      meta: { dirty, error, invalid, submitFailed, touched, warning },
      name,
      onChange,
      onChangeDate,
      optional,
      placeholder,
      required,
      rows,
      showCharacterCount,
      type,
      inputMode,
      value,
      customRef,
      className,
      onClick,
    } = this.props;

    const { hasFocus, passwordIsVisible, passwordToggleClicked } = this.state;
    const isTextarea = type === 'textarea';
    let isInvalid = (dirty || submitFailed) && invalid && (touched || isTextarea);
    let invalidText = isInvalid ? error : null;
    const shouldShowError = isInvalid || !!warning;
    let containerClasses = classnames(className, {
      [styles.hasFocus]: hasFocus,
      [styles.hasValue]: (value && value.length > 0) || placeholder,
      [styles.inputContainer]: type !== 'textarea',
      [styles.invalid]: shouldShowError,
      [styles.textareaContainer]: type === 'textarea',
    });
    let formField;

    const getDescribedBy = showDescribedByMessage => {
      if (showDescribedByMessage && ariaDescribedBy) {
        return `${id}-subtext ${ariaDescribedBy}`;
      }
      if (!showDescribedByMessage && ariaDescribedBy) {
        return `${ariaDescribedBy}`;
      }
      if (showDescribedByMessage && !ariaDescribedBy) {
        return `${id}-subtext`;
      }
      return null;
    };

    const showDescribedByMessage = !!helpText || !!invalidText || !!warning;
    const describedBy = getDescribedBy(showDescribedByMessage);
    if (type === 'textarea') {
      formField = (
        <textarea
          aria-describedby={describedBy}
          aria-invalid={isInvalid}
          aria-required={required}
          autoComplete={autoComplete}
          // eslint-disable-next-line
          autoFocus={autofocus}
          className={styles.textarea}
          disabled={disabled}
          id={id}
          maxLength={maxlength}
          name={name}
          onBlur={this.handleBlur}
          onChange={onChange}
          onFocus={this.handleFocus}
          placeholder={placeholder}
          rows={rows}
          value={value}
          data-cs-mask // for contentSquare masking
        />
      );
    } else if (type === 'date') {
      if (value.length >= 8) {
        const validDate = convertToDate(value);
        if (!validDate) {
          isInvalid = true;
          invalidText = 'Please enter a valid date';
          containerClasses = classnames(className, {
            [styles.hasFocus]: hasFocus,
            [styles.hasValue]: (value && value.length > 0) || placeholder,
            [styles.inputContainer]: type !== 'textarea',
            [styles.invalid]: isInvalid,
            [styles.textareaContainer]: type === 'textarea',
          });
        }
      }
      const inputProps = {
        autoComplete,
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-labelledby': `${id}-label`,
        'aria-required': required,
        className: classnames(styles.input),
        disabled,
        id,
        maxLength: maxlength,
        onChangeDate,
        name,
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onClick,
        placeholder,
        type: 'tel',
        inputMode,
        value,
      };
      formField = (
        <Fragment>
          <input
            {...inputProps}
            ref={customRef}
            // eslint-disable-next-line react/no-unknown-property
            guide={false}
            onClick={onClick}
            onChange={this.handleChange}
          />
        </Fragment>
      );
    } else {
      const inputType = type === 'password' && passwordIsVisible ? 'text' : type;
      const inputProps = {
        'aria-describedby': describedBy,
        'aria-invalid': isInvalid,
        'aria-labelledby': `${id}-label`,
        'aria-required': required,
        autoComplete,
        autoFocus: autofocus,
        className: classnames(styles.input, {
          [styles.password]: type === 'password',
        }),
        disabled,
        id,
        maxLength: maxlength,
        name,
        onBlur: this.handleBlur,
        onChange,
        onClick,
        onFocus: this.handleFocus,
        placeholder,
        type: inputType,
        inputMode,
        value,
      };
      const showPasswordToggle = type === 'password' && value.length > 0;

      formField = (
        <Fragment>
          <input {...inputProps} ref={customRef} />
          {showPasswordToggle && (
            <PasswordToggle
              onBlur={this.handleBlur}
              onClick={this.togglePasswordVisibility}
              passwordIsVisible={passwordIsVisible}
              passwordToggleClicked={passwordToggleClicked}
              errorPresent={shouldShowError}
            />
          )}
          {endAdornment && endAdornment.icon && endAdornment.iconError ? (
            <EndAdornment>
              {shouldShowError ? endAdornment.iconError : endAdornment.icon}
            </EndAdornment>
          ) : (
            shouldShowError && <LazyIcon name="Warning" className={styles.errorIcon} />
          )}
        </Fragment>
      );
    }

    return (
      <div className={containerClasses} data-testid="textbox">
        <div className={styles.fieldContainer} data-webviewid={dataWebviewid}>
          <Label
            className={styles.label}
            disabled={disabled}
            id={`${id}-label`}
            labelForId={id}
            label={label}
            optional={optional}
            required={required}
            type={type}
          />
          {formField}
          <SubText
            hasFocus={hasFocus}
            helpText={helpText}
            id={`${id}-subtext`}
            invalidText={invalidText || warning}
            maxlength={maxlength}
            showCharacterCount={showCharacterCount}
            value={value}
          />
        </div>
      </div>
    );
  }
}

Textbox.propTypes = {
  ariaDescribedBy: PropTypes.string,
  autoComplete: PropTypes.string,
  autofocus: PropTypes.bool,
  customRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  dataWebviewid: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    iconError: PropTypes.element.isRequired,
  }),
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  guide: PropTypes.bool,
  keepCharPositions: PropTypes.bool,
  maxlength: PropTypes.number,
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        message: PropTypes.string,
        explanation: PropTypes.shape({
          title: PropTypes.string,
          body: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        }),
      }),
    ]),
    invalid: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    warning: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  normalizeOnBlur: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onChangeDate: PropTypes.func,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  placeholderChar: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  showCharacterCount: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'tel', 'textarea', 'date'])
    .isRequired,
  inputMode: PropTypes.oneOf(['text', 'email', 'number', 'password', 'tel', 'textarea', 'date']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

Textbox.defaultProps = {
  customRef: null,
  ariaDescribedBy: null,
  autoComplete: '',
  autofocus: false,
  dataWebviewid: null,
  disabled: false,
  endAdornment: null,
  guide: false,
  helpText: null,
  keepCharPositions: false,
  maxlength: null,
  name: null,
  normalizeOnBlur: null,
  onBlur: () => {},
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  onChangeDate: () => {},
  optional: true,
  placeholder: null,
  placeholderChar: null,
  required: false,
  rows: 3,
  showCharacterCount: false,
  value: null,
  className: '',
  inputMode: undefined,
};
